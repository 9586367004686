import React, { useEffect } from "react";
import { redirectTo } from "src/helpers/route";
const Redirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      redirectTo("/links");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <p>Redirecionando...</p>;
};
export default Redirect;
/*
import React from "react";
import Youtube from "emersonbroga-youtube-embed";
import { FacebookProvider, Comments } from "react-facebook";

import SEO from "src/components/SEO";
import About from "src/sections/About";
import Footer from "src/sections/Footer";
import {
  BASE_URL,
  NAME,
  FACEBOOK_APP_ID,
  MARATONA_JS_2,
} from "src/helpers/config";

import Dates from "src/components/Dates";
import maratonaDates from "../dates.json";

import "../maratona.scss";
import Social from "src/components/Social";

const Dia2 = () => {
  const videoId = "tHJeU1H2NbQ";

  const seoProps = {
    title: `Dia 2 - Maratona JS 2 - ${NAME}`,
    canonical: `${BASE_URL}/mararona-js/dia-2/`,
    description: `Se dá certo pra tantos devs, claro que vai dar certo pra você também! - ${MARATONA_JS_2}.`,
    image: `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
  };

  return (
    <div className="maratona-js video-page">
      <SEO {...seoProps} />
      <div className="wrapper">
        <div className="header">
          <div className="logo">
            <img
              loading="lazy"
              src="/images/logo-maratona-js-black.png"
              alt={`Logo ${MARATONA_JS_2}`}
            />
          </div>
        </div>
        <Dates dates={maratonaDates} />

        <h1>Dia 2</h1>
        <Youtube videoId={videoId} />
        <div className="description">
          <p>
            Seja bem vindo ao segundo dia da {MARATONA_JS_2}! <br />
            Espero que você aproveite bastante! <br />
            <strong>Lista de links:</strong>
          </p>
          <Social type="dark" />
        </div>
      </div>
      <div className="comments">
        <div className="wrapper">
          <h3>Comentários:</h3>
          <FacebookProvider appId={FACEBOOK_APP_ID}>
            <Comments href={seoProps.canonical} width="100%" />
          </FacebookProvider>
        </div>
      </div>
      <About />
      <Footer />
    </div>
  );
};

export default Dia2;
*/
